import {
  BrowserPluginArgs,
  GatsbyBrowser,
  PluginOptions,
  navigate,
} from "gatsby";
import {
  Container as ClientContainer,
  createRoot,
  hydrateRoot,
} from "react-dom/client";

import CombinedProvider from "./src/components/General/CombinedProviders";
import { Container } from "react-dom";
import Layout from "./src/components/Layout/Layout";
import React from "react";
import { getCustomDispatch } from "./src/hooks/useIsHome";
import { loadableReady } from "@loadable/component";

/**
 * Handles actions to perform before a route update.
 * Converts uppercase paths to lowercase if necessary.
 *
 * @param {Object} param0 - The parameters for the function.
 * @param {Location} param0.location - The current location object.
 */
export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({
  location,
}) => {
  if (pathnameIncludesUppercase(location)) navigateToLowerCase(location);
};

/**
 * Handles actions to perform after a route update.
 * Closes the accordion in the menu, updates the home flag, and triggers third-party page view events.
 *
 * @param {Object} param0 - The parameters for the function.
 * @param {Location} param0.location - The current location object.
 * @param {Location | null} param0.prevLocation - The previous location object.
 */
export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  const customDispatch = getCustomDispatch();

  if (customDispatch) {
    customDispatch({ type: "SET_IS_NAV_ACCORDION_OPEN", payload: false });

    const newPath = location.pathname;
    const oldPath = prevLocation ? prevLocation.pathname : newPath;
    if (oldPath === "/" || newPath === "/") {
      const isHome = newPath === "/";
      // console.log("isHome", isHome);
      customDispatch({ type: "SET_USE_TRANSPARENT_STYLE", payload: isHome });
    }
  }

  // Tells third parties about new page views.
  triggerThirdPartyPageViewEvents(location, prevLocation);
};

/**
 * Wraps the root element with the combined provider for useContext.
 *
 * @param {Object} param0 - The parameters for the function.
 * @param {React.ReactNode} param0.element - The root element of the React component tree.
 * @returns {React.ReactNode} The root element wrapped with the Combined provider.
 */
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <CombinedProvider>{element}</CombinedProvider>;
};

/**
 * Wraps each page element with a layout component to provide a consistent layout across all pages.
 *
 * @param {Object} param0 - The parameters for the function.
 * @param {React.ReactNode} param0.element - The page element of the React component tree.
 * @param {Object} param0.props - The props to be passed to the layout component.
 * @returns {React.ReactNode} The page element wrapped with the layout component.
 */
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>;
};

/**
 * Replaces the default hydration function with a custom one that uses
 * loadableReady and conditionally hydrates the root element based on
 * the environment. Unlike the other functions in this file, this one
 * does not use the GatsbyBrowser["replaceHydrateFunction"] type. Using
 * this type prevents the function from working.
 *
 * @param {BrowserPluginArgs} _args - The arguments passed to the function.
 * @param {PluginOptions} _options - The options passed to the function.
 * @returns {Function} A function that takes in the root element and container, and conditionally hydrates the root element based on the environment.
 */
export const replaceHydrateFunction = (
  _args: BrowserPluginArgs,
  _options: PluginOptions
) => {
  // console.log("replaceHydrateFunction called");
  const isProduction = process.env.NODE_ENV === "production";
  // console.log("isProduction", isProduction);

  return (element: React.ReactNode, container: Container | null) => {
    // console.log("replaceHydrateFunction", element, container);
    loadableReady(() => {
      // console.log("loadableReady called");
      if (isProduction) {
        // console.log("hydrateRoot called");
        hydrateRoot(container as Element | Document, element);
      } else {
        // console.log("createRoot called");
        const root = createRoot(container as ClientContainer);
        root.render(element);
      }
    });
  };
};

/**
 * Checks if the location path contains an uppercase value
 * @param location The current URL location object
 * @returns True if an uppercase letter is found in the URL path
 */
const pathnameIncludesUppercase = (location: Location) => {
  return location.pathname.match(/[A-Z]/g);
};

/**
 * Navigates to a lowercase version of a URL
 * @param location The current URL location object
 */
const navigateToLowerCase = (location: Location) => {
  let newPath = location.pathname.toLowerCase();
  if (location.search.length > 0) newPath = newPath + location.search;
  if (location.hash.length > 0) newPath = newPath + location.hash;
  navigate(newPath);
};

/**
 * Triggers a page view event on Google Analytics and Facebook whenever a
 * user navigates from one page to another. This doesn't get triggered when
 * a user first loads the page as these page view events are called when the
 * third party script is initialised.
 *
 * @param {Location} location - The current URL location object.
 * @param {Location | null} prevLocation - The URL location object of the previous page.
 */
const triggerThirdPartyPageViewEvents = (
  location: Location,
  prevLocation: Location | null
) => {
  // Ensure the code runs only in the browser environment
  if (typeof window !== "undefined") {
    // Check if the gtag function (Google Analytics) is available and if there is a previous location
    if (typeof window.gtag === "function" && prevLocation) {
      // Trigger a page view event on Google Analytics with the current page path
      window.gtag("event", "page_view", {
        page_path: location
          ? `${location.pathname}${location.search}${location.hash}`
          : undefined,
      });
    }
    // Check if the fbq function (Facebook Pixel) is available and if there is a previous location
    if (typeof window.fbq === "function" && prevLocation) {
      // Trigger a page view event on Facebook Pixel
      window.fbq(`track`, `PageView`);
    }
  }
};
