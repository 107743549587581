import "/src/css/main.css";

import React, { useEffect } from "react";
import { faro, withFaroErrorBoundary } from "@grafana/faro-react";
import { initializeCustomDispatch, useIsHome } from "../../hooks/useIsHome";

import { initializeFaroMonitoring } from "../../monitoring";

// import "/src/css/slick.css";
// import "/src/css/slick-theme.css";

const Layout = ({ children }: { children: React.ReactNode }) => {
  if (typeof faro.api === "undefined") initializeFaroMonitoring();
  const context = useIsHome();

  useEffect(() => {
    if (context) {
      initializeCustomDispatch(context.customDispatch);
    }
  }, [context]);
  return <>{children}</>;
};

// export default Layout;
export default withFaroErrorBoundary(Layout, {});
