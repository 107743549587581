import { ApplicationFormProvider } from "../../store/applicationForm";
import { IsHomeProvider } from "../../store/isHome";
import React from "react";
import { RequestACallbackProvider } from "../../store/requestACallback";

const CombinedProvider = ({ children }: { children: React.ReactNode }) => (
  <IsHomeProvider>
    <RequestACallbackProvider>
      <ApplicationFormProvider>{children}</ApplicationFormProvider>
    </RequestACallbackProvider>
  </IsHomeProvider>
);

export default CombinedProvider;
