import React, { ReactNode, createContext, useReducer } from "react";

// Define the state
interface IsHomeState {
  useTransparentStyle: boolean;
  isNavAccordionOpen: boolean;
}

// Define the actions
export interface IsHomeAction {
  type: "SET_USE_TRANSPARENT_STYLE" | "SET_IS_NAV_ACCORDION_OPEN";
  payload: boolean;
}

// Define the initial state
const initialIsHomeState: IsHomeState = {
  useTransparentStyle: true,
  isNavAccordionOpen: false,
};

// Create the context
const IsHomeContext = createContext<
  | {
      state: IsHomeState;
      dispatch: React.Dispatch<IsHomeAction>;
      customDispatch: (_action: IsHomeAction) => void; // Add this line
    }
  | undefined
>(undefined);

/**
 * Reduces the state of the home component based on the given action.
 *
 * @param {IsHomeState} state - The current state of the home component.
 * @param {IsHomeAction} action - The action to be performed on the state.
 * @return {IsHomeState} The new state of the home component after the action has been applied.
 */
const isHomeReducer = (
  state: IsHomeState,
  action: IsHomeAction
): IsHomeState => {
  switch (action.type) {
    case "SET_USE_TRANSPARENT_STYLE":
      return {
        ...state,
        useTransparentStyle: action.payload,
      };
    case "SET_IS_NAV_ACCORDION_OPEN":
      return {
        ...state,
        isNavAccordionOpen: action.payload,
      };
    default:
      return state;
  }
};

/**
 * A higher-order function that returns a custom dispatch function.
 *
 * @param {_dispatch} dispatch - The original dispatch function to be wrapped.
 * @return {(_action: IsHomeAction) => void} A custom dispatch function that calls the original dispatch function with the given action.
 */
const customDispatch: (
  _dispatch: React.Dispatch<IsHomeAction>
) => (_action: IsHomeAction) => void = (dispatch) => (action) => {
  dispatch(action);
};

/**
 * A provider component for the isHome context.
 *
 * @param {Object} children - The child components to be wrapped by the provider.
 * @return {JSX.Element} The provider component with the context value.
 */
const IsHomeProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(isHomeReducer, initialIsHomeState);

  return (
    <IsHomeContext.Provider
      value={{ state, dispatch, customDispatch: customDispatch(dispatch) }}
    >
      {children}
    </IsHomeContext.Provider>
  );
};

export { IsHomeProvider, IsHomeContext };
